<template>
  <div class="tradePINPage bgfff">
    <div class="app h5MaxWidth">
      <header>
        <div class="header h5MaxWidth clear display alignCenter">
          <div
            class="back display alignCenter justifyCenter"
            onclick="window.history.go(-1)"
          >
            <i class="iconfont icon-arrow-left"></i>
          </div>
          <h2 class="flex1">Reset Trade Password</h2>
          <div class="navskip" id="sideMenu"></div>
        </div>
      </header>
      <div class="main">
        <div class="tradePIN forms">
          <form id="tradePIN" action="#" method="post" onsubmit="">
            <ul>
              <li>
                <div class="inputArea display alignCenter">
                  <div class="leftSideBox display alignCenter">
                    <i class="i_inputIcon iconfont icon-lock"></i>
                  </div>
                  <div class="trueInpt display alignCenter flex1">
                    <input
                      type="password"
                      v-model="oldPwd"
                      placeholder="Old trade Password"
                      class="confirmPassword fillArea eyeClose pwdICO flex1"
                    />
                  </div>
                </div>
              </li>
              <li>
                <div class="inputArea display alignCenter">
                  <div class="leftSideBox display alignCenter">
                    <i class="i_inputIcon iconfont icon-barcode"></i>
                  </div>
                  <div class="trueInpt display alignCenter flex1">
                    <input
                      type="password"
                      v-model="newPwd"
                      class="checkCode fillArea flex1"
                      placeholder="New trade Password"
                    />
                  </div>
                </div>
              </li>
            </ul>

            <div class="actionBar display alignCenter alignStretch justifyEnd">
              <div
                class="recoverBtn clickBtn display alignCenter justifyCenter"
                @click="resetPassword()"
              >
                <p>Confirm</p>
                <i class="iconfont icon-arrow-right-3"></i>
              </div>
            </div>
          </form>
        </div>
        <div class="tipsBox">
          <div id="tips" class="tips">
            <div class="titleBar display alignCenter justifyDetween">
              <div>
                <h3>Explain</h3>
              </div>
            </div>
            <div class="explain">
              <p>
                1. The transaction password is an important voucher for you to
                withdraw, add a bank account, or delete a bank account. Please
                be sure to keep it properly.
              </p>
              <p>
                2. Do not set the transaction password to match the login
                password.
              </p>
              <p>
                3. If you forget your transaction password, please reset it
                through your mobile OTP verification code.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      uid:"",
      oldPwd:"",
      newPwd:""
    };
  },
  components: {},
  methods: {
    resetPassword(){
      this.getAjax(
        "api/user/tradepwd",
        {
          uid: this.uid,
          oldPass: this.property,
          newPass: this.newPwd,
        },
        "post"
      ).then((res) => {
        if (res.code === 200) {
          this.tips("success!");
        } else {
          this.tips(res.msg, "warning");
        }
      });
    }
  },
  mounted() {
    this.uid = this.getItem("uid");
  },
};
</script>

<style scoped lang="less">
</style>
